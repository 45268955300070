import React from 'react';
import logo from '../assets/assets/Logo.png'

export const LandingPage = () => {
    return (
        <div className="container">
            <div style={{width: '100%', textAlign: 'center'}}>
                <img className="logo img-fluid" src={logo} alt="logo" />
            </div>
        </div>
    )
}