import React, { useState } from 'react';

export const Form = () => {

    // Local State For Forms //
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [occupation, setOccupation] = useState('');
    const [igHandle, setHandle] = useState('');

    // Functions for Setting State //
    const setFirst = (e) => {
        setFirstName(e.target.value);
    }

    const setLast = e => {
        setLastName(e.target.value);
    }

    const setEmailAddress = e => {
        setEmail(e.target.value);
    }

    const setCityName = e => {
        setCity(e.target.value);
    }

    const setRole = e => {
        setOccupation(e.target.value);
    }

    const setIgHandle = e => {
        setHandle(e.target.value);
    }

    // Validation Checks //
    const isFirstNameValid = firstName.trim().length > 0;
    const isLastNameValid = lastName.trim().length > 0;
    const isEmailValid  = email.trim().length > 0;
    const isCityValid = city.trim().length > 0;
    const isOccupationValid = occupation.trim().length > 0;

    const isFormValid = isFirstNameValid && isLastNameValid && isEmailValid && isCityValid && isOccupationValid;

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="card-title">Join Now</h3>
                <p className="card-text">
                    Please enter your information below to become a member today!
                </p>
                <form name="sign-up-form" method="POST" >
                    <input type="hidden" name="form-name" value="sign-up-form" />
                    <div className="form-row">
                        <div className="col form-group">
                            <input className="form-control" type="text" name="first-name" placeholder="First Name" onChange={setFirst} value={firstName} />
                        </div>
                        <div className="col form-group">
                            <input className="form-control" type="text" name="last-name" placeholder="Last Name" onChange={setLast} value={lastName} />
                        </div>
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="text" name="social-handle" placeholder="Instagram Handle" onChange={setIgHandle} value={igHandle} />
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="email" name="email" placeholder="Email Address" onChange={setEmailAddress} value={email} />
                    </div>
                    <div className="form-row">
                        <div className="col form-group">
                            <input className="form-control" type="text" name="city" placeholder="City" onChange={setCityName} value={city} />
                        </div>
                        <div className="col form-group">
                            <input className="form-control" type="text" name="occupation" placeholder="Occupation" onChange={setRole} value={occupation}/>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-primary w-100" type="submit" disabled={!isFormValid}>BECOME A MEMBER</button>
                    </div>
                </form>
            </div>
        </div>
    )
}