// local imports
import React from 'react';
import { LandingPage } from './containers/landingPage';
import { Description } from './components/description';
import { Form } from './components/form';

// style imports
import './App.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.css'

export const App = () => {
  return (
    <div>
      <LandingPage />
      <section>
        <div className="container">
          <div className="row m-0">
            <div className="col-md-6 p-0 mt-3 mb-3 d-flex justify-content-center align-items-center">
              <Description />
            </div>
            <div className="col-md-6 p-0 mt-3 mb-3 d-flex justify-content-center">
              <Form />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
